import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Image, Table, Row, Attributes, Expander, List, Tabs, WarningIcon } from './components';
import { InfoIcon } from './components/Icons';
import { getFilesCurl, getFilesJava, getFilesJavascript, getFilesNode, getFilesPerl, getFilesPython, getFilesPhp, getFilesRuby, getFilesGo, getFilesC, getFilesVB, getFilesGroovy, getFilesObjectiveC, getFilesSwift, deleteFilesCurl, deleteFilesJava, deleteFilesJavascript, deleteFilesNode, deleteFilesPerl, deleteFilesPython, deleteFilesPhp, deleteFilesRuby, deleteFilesGo, deleteFilesC, deleteFilesVB, deleteFilesGroovy, deleteFilesObjectiveC, deleteFilesSwift, manageFilesRaw, manageFilesCurl, manageFilesJava, manageFilesJavascript, manageFilesNode, manageFilesPerl, manageFilesPython, manageFilesPhp, manageFilesRuby, manageFilesGo, manageFilesC, manageFilesVB, manageFilesGroovy, manageFilesObjectiveC, manageFilesSwift, upload1FilesRaw, upload1FilesCurl, upload1FilesJava, upload1FilesJavascript, upload1FilesNode, upload1FilesPerl, upload1FilesPython, upload1FilesPhp, upload1FilesRuby, upload1FilesGo, upload1FilesC, upload1FilesVB, upload1FilesGroovy, upload1FilesObjectiveC, upload1FilesSwift, upload2FilesRaw, upload2FilesCurl, upload2FilesJava, upload2FilesJavascript, upload2FilesNode, upload2FilesPerl, upload2FilesPython, upload2FilesPhp, upload2FilesRuby, upload2FilesGo, upload2FilesC, upload2FilesVB, upload2FilesGroovy, upload2FilesObjectiveC, upload2FilesSwift, upload3FilesRaw, upload3FilesCurl, upload3FilesJava, upload3FilesJavascript, upload3FilesNode, upload3FilesPerl, upload3FilesPython, upload3FilesPhp, upload3FilesRuby, upload3FilesGo, upload3FilesC, upload3FilesVB, upload3FilesGroovy, upload3FilesObjectiveC, upload3FilesSwift, downloadFilesCurl, downloadFilesJava, downloadFilesJavascript, downloadFilesNode, downloadFilesPerl, downloadFilesPython, downloadFilesPhp, downloadFilesRuby, downloadFilesGo, downloadFilesC, downloadFilesVB, downloadFilesGroovy, downloadFilesObjectiveC, downloadFilesSwift } from 'requestExamples/files';
export const endpoints = [{
  param: 'GET',
  value: '/nodes/pth/{path}/{filename}',
  subtitle: 'Retrieve file information',
  link: '#retrieve-file-information'
}, {
  param: 'POST',
  subtitle: 'copy/move',
  value: '/nodes/pth/{path}',
  subtitle: 'Copy or move a file',
  link: '#copy-or-move-a-file'
}, {
  param: 'PUT',
  subtitle: 'upload',
  value: '/nodes/pth/{path}',
  subtitle: 'Upload a file',
  link: '#upload-a-file'
}, {
  param: 'GET',
  value: '/files/id/Document/{doc_id}',
  subtitle: 'download a file',
  link: '#download-a-file'
}, {
  param: 'DELETE',
  value: '/nodes/pth/{path}',
  subtitle: 'Delete a file',
  link: '#delete-a-file'
}];
export const baseUrlGet = [{
  param: 'GET',
  value: '/nodes/pth/{path}/{filename}'
}];
export const baseUrlCopy = [{
  param: 'POST',
  value: '/nodes/pth/{path}/{filename}'
}];
export const baseUrlUpload = [{
  param: 'PUT',
  value: '/nodes/pth/{path}',
  link: '#add-the-file-metadata'
}, {
  param: 'PUT',
  value: '/nodes/upload/{file_id}',
  link: '#add-the-document-version'
}, {
  param: 'PUT',
  value: '/nodes/upload/{file_id}/{doc_version_id}',
  link: '#upload-the-file'
}];
export const baseUrlUpload1 = [{
  param: 'PUT',
  value: '/nodes/pth/{path}'
}];
export const baseUrlUpload2 = [{
  param: 'PUT',
  value: '/nodes/upload/{file_id}'
}];
export const baseUrlUpload3 = [{
  param: 'PUT',
  value: '/nodes/upload/{file_id}/{doc_version_id}'
}];
export const baseUrlDownload = [{
  param: 'GET',
  value: '/files/id/Document/{doc_id}'
}];
export const baseUrlDelete = [{
  param: 'DELETE',
  value: '/nodes/pth/{path}'
}];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  endpoints,
  baseUrlGet,
  baseUrlCopy,
  baseUrlUpload,
  baseUrlUpload1,
  baseUrlUpload2,
  baseUrlUpload3,
  baseUrlDownload,
  baseUrlDelete,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "files"
    }}>{`Files`}</h1>
    <Row mdxType="Row">
	<div>
		<p>
			Files are managed using the /nodes/pth resource. This structure is key to integrating with SmartVault if you
			are using the generic integration model.
			<br />
			You can retrieve, copy, move and delete a file, as well as upload and download them.
		</p>
	</div>
	<Table title="endpoints" rows={endpoints} mdxType="Table" />
    </Row>
    <br />
    <br />
    <br />
    <hr style={{
      "width": "50%"
    }} />
    <br />
    <br />
    <h2 {...{
      "id": "the-file-object"
    }}>{`The file object`}</h2>
    <Expander title="Attributes" mdxType="Expander">
	<Attributes items={[{
        name: 'name',
        type: 'string',
        description: 'The name of the file.'
      }, {
        name: 'description',
        type: 'string',
        description: 'A description of the file.'
      }, {
        name: 'createdOn',
        type: 'string',
        description: 'Date when the file was created.'
      }, {
        name: 'modifiedOn',
        type: 'string',
        description: 'Last time the file was modified.'
      }, {
        name: 'size',
        type: 'number',
        description: 'Size in bytes of the object.'
      }, {
        name: 'uri',
        type: 'string',
        description: 'Endpoint pathname used to get the file.'
      }, {
        name: 'dav_uri',
        type: 'number',
        description: 'Endpoint pathname used to get the file.'
      }, {
        name: 'download_uri',
        type: 'string',
        description: 'Endpoint pathname to download the file.'
      }, {
        name: 'your_permissions',
        type: 'number',
        description: 'Your user permissions over the file.'
      }, {
        name: 'nodeType',
        type: 'string',
        description: 'Type of node. FileNodeType for files.'
      }, {
        name: 'link_uri',
        type: 'string',
        description: 'Dashboard link for the file. It contains the file ID right after "&id=" characters.'
      }, {
        name: 'preview_link_uri',
        type: 'string',
        description: 'Dashboard link to preview the file. This url contains the file ID right after "&id=" characters.'
      }, {
        name: 'download_link_uri',
        type: 'string',
        description: 'Dashboard link to download the file. This url contains the file ID right after "&id=" characters.'
      }, {
        name: 'total_children',
        type: 'number',
        description: 'Total number of children files.'
      }]} mdxType="Attributes" />
	<br />
	<h4>FileExProperties object</h4>
	<hr />
	<Attributes items={[{
        name: 'preview_available',
        type: 'string',
        description: "Indicates if there's a preview available for the file.",
        values: ['True', 'False']
      }, {
        name: 'thumbnail_available',
        type: 'string',
        description: "Indicates if there's a thumbnail available for the file.",
        values: ['True', 'False']
      }, {
        name: 'document_state',
        type: 'string',
        description: 'Indicates if the file only has metadata or the file itself has been uploaded already.',
        values: ['DocumentPending, DocumentAvailable']
      }, {
        name: 'storage_location',
        type: 'string',
        description: 'Location where the documents are being stored. For API requests, the only option is CLOUD.',
        values: ['CLOUD']
      }, {
        name: 'account_name',
        type: 'string',
        description: 'Account name used for the file upload.'
      }]} mdxType="Attributes" />
	<br />
	<h4>Upload_status object. Info related to the file. Will change during the file upload process.</h4>
	<hr />
	<Attributes items={[{
        name: 'length',
        type: 'number',
        description: "File size in bytes. Will be different than original_length if it's been compressed."
      }, {
        name: 'original_length',
        type: 'number',
        description: 'Original described file size in bytes.'
      }, {
        name: 'md5_checksum',
        type: 'string',
        description: "File md5 checksum. The server generates one automatically on the 3rd step of the upload process if it hasn't been specified in step 2."
      }, {
        name: 'write_offset',
        type: 'number',
        description: 'Present when the file size is big enough so it needs to be split in parts. the write_offsets indicates where the file chunk starts.'
      }]} mdxType="Attributes" />
	<br />
	<h4>
		VersionExProperties object. File metadata present on the response of the first step during the file upload
		process.
	</h4>
	<hr />
	<Attributes items={[{
        name: 'uuid',
        type: 'string',
        description: 'uuid of the upload process'
      }, {
        name: 'version',
        type: 'number',
        description: 'Version of the file'
      }, {
        name: 'md5',
        type: 'string',
        description: 'md5 of the file'
      }, {
        name: 'type',
        type: 'string',
        description: 'type of entity being managed. In case of managing files, this will be "Document"',
        values: ['Account', 'Vault', 'Folder', 'Company', 'Document']
      }, {
        name: 'parent_uuid',
        type: 'string',
        description: 'Parent uuid'
      }, {
        name: 'parent_type',
        type: 'string',
        description: 'Parent type of entity being managed.',
        values: ['Account', 'Vault', 'Folder', 'Company', 'Document']
      }]} mdxType="Attributes" />
    </Expander>

    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />
    <h2 {...{
      "id": "retrieve-file-information"
    }}>{`Retrieve file information`}</h2>
    <Row mdxType="Row">
	<div>
		<p>
			You can also use the /nodes/pth structure to get information about a file, including its file ID and various
			links for previewing and downloading the file.
		</p>
	</div>
	<Table title="Get file" rows={baseUrlGet} mdxType="Table" />
    </Row>
    <br />
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'path',
      type: 'string',
      description: 'The folder path containing the file, including the account and vault'
    }, {
      name: 'filename',
      type: 'string',
      description: 'The full file name, including the extension.'
    }]} mdxType="Attributes" />
    <br />
    <br />
    <p><strong parentName="p">{`Query parameters`}</strong></p>
    <hr></hr>
    <InfoIcon mdxType="InfoIcon" />
In the SmartVault infrastructure, all files are stored under a folder. To list all the files a folder has we can simply use
the "children" query parameter as well as some others to tweak the endpoint response.
    <br />
    <br />
    <Expander title="query parameters" mdxType="Expander">
	<Attributes items={[{
        name: 'children',
        type: 'number',
        description: 'Specifies the depth of children to fetch back. 0 is just the element specified, and 1 is just the direct children. Can be any integer value "0" or greater. Default is 0.'
      }, {
        name: 'direction',
        type: 'string (optional)',
        description: 'Sets the sorting direction. Can be either "asc" for ascending results or "desc" for descending. Default is "asc".',
        values: ['asc', 'desc']
      }, {
        name: 'eprop',
        type: 'string (optional)',
        description: 'Tells the server to return extended property information in the results. It may be set to "0", "1", "true" or "false". Default is false.'
      }, {
        name: 'page',
        type: 'number (optional)',
        description: 'Sets the zero-based number of the result page to be returned. When combined with per_page and a sorting criteria, this can be used to paginate through larger result sets. Default is 0.'
      }, {
        name: 'per_page',
        type: 'number (optional)',
        description: 'Sets the maximum number of items to return in the query. Default is 50.'
      }, {
        name: 'sort',
        type: 'string (optional)',
        description: 'Sets the information for the results to be ordered. The value "relevance" is used for best-match sorting of search results. Default is "name".',
        values: ['name', 'modified_date', 'size', 'relevance']
      }]} mdxType="Attributes" />
    </Expander>
    <br />
    <br />
    <p><strong parentName="p">{`Request`}</strong></p>
    <hr></hr>
    <Tabs items={[{
      tabTitle: 'cURL',
      tabInfo: getFilesCurl
    }, {
      tabTitle: 'Java',
      tabInfo: getFilesJava
    }, {
      tabTitle: 'Javascript',
      tabInfo: getFilesJavascript
    }, {
      tabTitle: 'Node',
      tabInfo: getFilesNode
    }, {
      tabTitle: 'Perl',
      tabInfo: getFilesPerl
    }, {
      tabTitle: 'Python',
      tabInfo: getFilesPython
    }, {
      tabTitle: 'PHP',
      tabInfo: getFilesPhp
    }, {
      tabTitle: 'Ruby',
      tabInfo: getFilesRuby
    }, {
      tabTitle: 'Go',
      tabInfo: getFilesGo
    }, {
      tabTitle: 'C#',
      tabInfo: getFilesC
    }, {
      tabTitle: 'Visual Basic',
      tabInfo: getFilesVB
    }, {
      tabTitle: 'Groovy',
      tabInfo: getFilesGroovy
    }, {
      tabTitle: 'Objective-C',
      tabInfo: getFilesObjectiveC
    }, {
      tabTitle: 'Swift',
      tabInfo: getFilesSwift
    }]} mdxType="Tabs" />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <p>{`Returns a `}<a parentName="p" {...{
        "href": "#the-file-object"
      }}>{`file object`}</a>{`.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "name": "user3.jpg",
        "description": "",
        "createdOn": "2020-05-15T05:46:07-06:00",
        "modifiedOn": "2020-05-15T05:46:08-06:00",
        "size": 16621,
        "uri": "/nodes/pth/SmartVault Account/Clients/A-D/AutoInsurance, Test for/Permanent File/user3.jpg",
        "dav_uri": "/nodes/pth/SmartVault Account/Clients/A-D/AutoInsurance, Test for/Permanent File/user3.jpg",
        "download_uri": "/files/id/Document/gkXqR4kCQ0S92b2wWMjVxg",
        "your_permissions": 41943153,
        "nodeType": "FileNodeType",
        "link_uri": "https://my.smartvault.com/users/secure/ElementBrowser.aspx?type=Document&id=gkXqR4kCQ0S92b2wWMjVxg",
        "preview_link_uri": "https://my.smartvault.com/users/secure/ElementBrowser.aspx?type=Document&id=gkXqR4kCQ0S92b2wWMjVxg",
        "download_link_uri": "https://my.smartvault.com/users/secure/DownloadFile.aspx?d=gkXqR4kCQ0S92b2wWMjVxg",
        "auto_name": false,
        "auto_description": false
    }
}
`}</code></pre>
    </Expander>
    <br />
    <p>{`Returns an `}<a parentName="p" {...{
        "href": "/api/errors#error-object"
      }}>{`error object`}</a>{` if the file doesn't exist.`}</p>
    <Expander title="error object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": false,
        "error_code": -520093684,
        "error_text": "Object not found.",
        "instance_guid": "693476f2-8971-450d-a7b6-287cc986f3df",
        "status_code": 404
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />
    <h2 {...{
      "id": "copy-or-move-a-file"
    }}>{`Copy or move a file`}</h2>

    <Row mdxType="Row">
	<div>
		<span>
			To copy or move a file, you need to execute a <span style={{
            "color": "#47966c"
          }}>POST</span> request to the url
			pointing at the file you'd like to copy / move and pass the parameters specified below in the body of the
			request.
		</span>
		<br />
		<WarningIcon mdxType="WarningIcon" />
		Note that you need to specify the filename, otherwise it'll move the whole folder.
	</div>
	<Table title="Copy or move file" rows={baseUrlCopy} mdxType="Table" />
    </Row>
    <br />
    <br />
    <p><strong parentName="p">{`Body parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'dst_uri',
      type: 'string',
      description: 'The destination URI starting from /nodes/pth. This must include the destination file name and extension.'
    }, {
      name: 'replace',
      type: 'string',
      description: 'Sets what to do if the file already exists.',
      values: ['fail', 'replace']
    }]} mdxType="Attributes" />
    <br />
    <p>{`For copying a file the body of the request would need to be:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`{
  "copy": {
    "dst_uri": "/nodes/pth/{path}/{filename}",
    "replace": "Replace"
  }
}
`}</code></pre>
    <p>{`Instead, if what you want is to move a certain file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`{
  "move": {
    "dst_uri": "/nodes/pth/{path}/{filename}",
    "replace": "Replace"
  }
}
`}</code></pre>
    <br />
    <p><strong parentName="p">{`Request`}</strong></p>
    <hr></hr>
    <p>{`We'll use the "copy" keyword for the request body as default for the examples below. As it's specified above, the body of the request would need to change in case you'd wanted to move the file instead.`}</p>
    <br />
    <Tabs items={[{
      tabTitle: 'Raw',
      tabInfo: manageFilesRaw
    }, {
      tabTitle: 'cURL',
      tabInfo: manageFilesCurl
    }, {
      tabTitle: 'Java',
      tabInfo: manageFilesJava
    }, {
      tabTitle: 'Javascript',
      tabInfo: manageFilesJavascript
    }, {
      tabTitle: 'Node',
      tabInfo: manageFilesNode
    }, {
      tabTitle: 'Perl',
      tabInfo: manageFilesPerl
    }, {
      tabTitle: 'Python',
      tabInfo: manageFilesPython
    }, {
      tabTitle: 'PHP',
      tabInfo: manageFilesPhp
    }, {
      tabTitle: 'Ruby',
      tabInfo: manageFilesRuby
    }, {
      tabTitle: 'Go',
      tabInfo: manageFilesGo
    }, {
      tabTitle: 'C#',
      tabInfo: manageFilesC
    }, {
      tabTitle: 'Visual Basic',
      tabInfo: manageFilesVB
    }, {
      tabTitle: 'Groovy',
      tabInfo: manageFilesGroovy
    }, {
      tabTitle: 'Objective-C',
      tabInfo: manageFilesObjectiveC
    }, {
      tabTitle: 'Swift',
      tabInfo: manageFilesSwift
    }]} mdxType="Tabs" />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <p>{`Returns a `}<a parentName="p" {...{
        "href": "#the-file-object"
      }}>{`file object`}</a>{` if the object exist.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "name": "user3_copy.jpg",
        "description": "",
        "createdOn": "2020-05-15T09:08:17-05:00",
        "modifiedOn": "2020-05-15T05:46:08-06:00",
        "size": 16621,
        "uri": "/nodes/pth/SmartVault Account/Clients/A-D/AutoInsurance, Test for/Test/user3_copy.jpg",
        "dav_uri": "/nodes/pth/SmartVault Account/Clients/A-D/AutoInsurance, Test for/Test/user3_copy.jpg",
        "download_uri": "/files/id/Document/cDeR_LpNHUGdGUU1OEOBCA",
        "your_permissions": 41943153,
        "nodeType": "FileNodeType",
        "link_uri": "https://my.smartvault.com/users/secure/ElementBrowser.aspx?type=Document&id=cDeR_LpNHUGdGUU1OEOBCA",
        "preview_link_uri": "https://my.smartvault.com/users/secure/ElementBrowser.aspx?type=Document&id=cDeR_LpNHUGdGUU1OEOBCA",
        "download_link_uri": "https://my.smartvault.com/users/secure/DownloadFile.aspx?d=cDeR_LpNHUGdGUU1OEOBCA",
        "auto_name": false,
        "auto_description": false
    }
}
`}</code></pre>
    </Expander>
    <br />
    <p>{`Returns an `}<a parentName="p" {...{
        "href": "/api/errors#error-object"
      }}>{`error object`}</a>{` if the object can't be found.`}</p>
    <Expander title="error object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": false,
        "error_code": -520093684,
        "error_text": "Object not found.",
        "instance_guid": "9037427a-8abc-410e-9374-60fede54ef75",
        "status_code": 404
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />
    <h2 {...{
      "id": "upload-a-file"
    }}>{`Upload a file`}</h2>

    <Row mdxType="Row">
	<div>
		<p>Uploading documents requires three steps, each of them related to a PUT request.</p>
		<List values={[{
          title: 'Add information about the document.'
        }, {
          title: 'Add the document version.'
        }, {
          title: 'Upload the file.'
        }]} mdxType="List" />
	</div>
	<Table title="Upload endpoints" rows={baseUrlUpload} mdxType="Table" />
    </Row>
    <br />
    <h3 {...{
      "id": "add-the-file-metadata"
    }}>{`Add the file metadata`}</h3>
    <hr></hr>

    <Row mdxType="Row">
	<div>
		<p>Specify the path to the file via param and the name of it on the body of the request.</p>
	</div>
	<Table title="Upload 1st step" rows={baseUrlUpload1} mdxType="Table" />
    </Row>
    <br />
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'path',
      type: 'string',
      description: 'The destination URI starting from /nodes/pth.'
    }]} mdxType="Attributes" />
    <br />
    <br />
    <p><strong parentName="p">{`Body parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'name',
      type: 'string',
      description: 'The name for the file to be uploaded.'
    }]} mdxType="Attributes" />
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`{
  "create_file": [
    {
      "name": "newUploadedFile.jpg"
    }
  ]
}
`}</code></pre>
    <br />
    <p><strong parentName="p">{`Request`}</strong></p>
    <hr></hr>
    <Tabs items={[{
      tabTitle: 'Raw',
      tabInfo: upload1FilesRaw
    }, {
      tabTitle: 'cURL',
      tabInfo: upload1FilesCurl
    }, {
      tabTitle: 'Java',
      tabInfo: upload1FilesJava
    }, {
      tabTitle: 'Javascript',
      tabInfo: upload1FilesJavascript
    }, {
      tabTitle: 'Node',
      tabInfo: upload1FilesNode
    }, {
      tabTitle: 'Perl',
      tabInfo: upload1FilesPerl
    }, {
      tabTitle: 'Python',
      tabInfo: upload1FilesPython
    }, {
      tabTitle: 'PHP',
      tabInfo: upload1FilesPhp
    }, {
      tabTitle: 'Ruby',
      tabInfo: upload1FilesRuby
    }, {
      tabTitle: 'Go',
      tabInfo: upload1FilesGo
    }, {
      tabTitle: 'C#',
      tabInfo: upload1FilesC
    }, {
      tabTitle: 'Visual Basic',
      tabInfo: upload1FilesVB
    }, {
      tabTitle: 'Groovy',
      tabInfo: upload1FilesGroovy
    }, {
      tabTitle: 'Objective-C',
      tabInfo: upload1FilesObjectiveC
    }, {
      tabTitle: 'Swift',
      tabInfo: upload1FilesSwift
    }]} mdxType="Tabs" />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <p>{`Returns a `}<a parentName="p" {...{
        "href": "/api/folders#the-folder-object"
      }}>{`folder object`}</a>{` if the object exist.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "name": "Test",
        "createdOn": "2020-05-15T06:03:29-06:00",
        "modifiedOn": "2020-05-15T06:03:29-06:00",
        "uri": "/nodes/pth/SmartVault Account/Clients/A-D/AutoInsurance, Test for/Test",
        "dav_uri": "/nodes/pth/SmartVault Account/Clients/A-D/AutoInsurance, Test for/Test",
        "your_permissions": 16787701,
        "nodeType": "FolderNodeType",
        "link_uri": "https://my.smartvault.com/users/secure/ElementBrowser.aspx?type=Folder&id=lFf_P2AUlUq3tttbj1jc3g",
        "children": [
            {
                "name": "newUploadedFile.jpg",
                "description": "",
                "createdOn": "2020-05-15T10:54:12-05:00",
                "modifiedOn": "2020-05-15T10:54:12-05:00",
                "uri": "/nodes/pth/SmartVault Account/Clients/A-D/AutoInsurance, Test for/Test/newUploadedFile.jpg",
                "dav_uri": "/nodes/pth/SmartVault Account/Clients/A-D/AutoInsurance, Test for/Test/newUploadedFile.jpg",
                "download_uri": "/files/id/Document/v2fFF5ZFnESuP3usjl5yHw",
                "your_permissions": 8388721,
                "nodeType": "FileNodeType",
                "link_uri": "https://my.smartvault.com/users/secure/ElementBrowser.aspx?type=Document&id=v2fFF5ZFnESuP3usjl5yHw",
                "preview_link_uri": "https://my.smartvault.com/users/secure/ElementBrowser.aspx?type=Document&id=v2fFF5ZFnESuP3usjl5yHw",
                "download_link_uri": "https://my.smartvault.com/users/secure/DownloadFile.aspx?d=v2fFF5ZFnESuP3usjl5yHw",
                "fileExProperties": {
                    "preview_available": "False",
                    "thumbnail_available": "False"
                },
                "versionExProperties": {
                    "uuid": "v2fFF5ZFnESuP3usjl5yHw",
                    "version": 0,
                    "md5": "d41d8cd98f00b204e9800998ecf8427e",
                    "type": "Document",
                    "parent_uuid": "lFf_P2AUlUq3tttbj1jc3g",
                    "parent_type": "Folder"
                },
                "auto_name": false,
                "auto_description": false
            }
        ]
    }
}
`}</code></pre>
    </Expander>
    <br />
    <p>{`Returns an `}<a parentName="p" {...{
        "href": "/api/errors#error-object"
      }}>{`error object`}</a>{` if the file name is not present in the request body.`}</p>
    <Expander title="error object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": false,
        "error_code": -2147467259,
        "error_text": "Message missing required fields: name"
    }
}
`}</code></pre>
    </Expander>
    <br />
    <h4 {...{
      "id": "the-file-id"
    }}>{`The file ID`}</h4>
    <hr></hr>
    <p>{`You should be able to see your new defined file in the "children" array of the answer.`}<br />{`
Right after the "&id" characters in the link_uri, preview_link_uri or download_link_uri parameters of the child object you should find the ID of the new uploaded file that you'll need for the next step.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`"link_uri": "https://my.smartvault.com/users/secure/ElementBrowser.aspx?type=Document&id=   v2fFF5ZFnESuP3usjl5yHw",
`}</code></pre>
    <p>{`In the given example the id would be `}<strong parentName="p">{`v2fFF5ZFnESuP3usjl5yHw`}</strong></p>
    <br />
    <br />
    <br />
    <h3 {...{
      "id": "add-the-document-version"
    }}>{`Add the document version`}</h3>
    <hr></hr>

    <Row mdxType="Row">
	<div>
		<p>Use the recently created file ID on the request and specify the file version on the body of the request.</p>
	</div>
	<Table title="Upload 2nd step" rows={baseUrlUpload2} mdxType="Table" />
    </Row>
    <br />
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'file_id',
      type: 'string',
      description: 'The file ID.'
    }]} mdxType="Attributes" />
    <br />
    <br />
    <p><strong parentName="p">{`Body parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'length',
      type: 'number',
      description: "The exact size in bytes of the file that you'll upload on the 3rd step."
    }]} mdxType="Attributes" />
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`{
    "length": 14346
}
`}</code></pre>
    <br />
    <Attributes items={[{
      name: 'md5_checksum',
      type: 'string (optional)',
      description: 'You can specify a md5 checksum of the file. The server will set the checksum of the file itself if no one is specified.'
    }]} mdxType="Attributes" />
    <br />
    <p><strong parentName="p">{`Request`}</strong></p>
    <hr></hr>
    <Tabs items={[{
      tabTitle: 'Raw',
      tabInfo: upload2FilesRaw
    }, {
      tabTitle: 'cURL',
      tabInfo: upload2FilesCurl
    }, {
      tabTitle: 'Java',
      tabInfo: upload2FilesJava
    }, {
      tabTitle: 'Javascript',
      tabInfo: upload2FilesJavascript
    }, {
      tabTitle: 'Node',
      tabInfo: upload2FilesNode
    }, {
      tabTitle: 'Perl',
      tabInfo: upload2FilesPerl
    }, {
      tabTitle: 'Python',
      tabInfo: upload2FilesPython
    }, {
      tabTitle: 'PHP',
      tabInfo: upload2FilesPhp
    }, {
      tabTitle: 'Ruby',
      tabInfo: upload2FilesRuby
    }, {
      tabTitle: 'Go',
      tabInfo: upload2FilesGo
    }, {
      tabTitle: 'C#',
      tabInfo: upload2FilesC
    }, {
      tabTitle: 'Visual Basic',
      tabInfo: upload2FilesVB
    }, {
      tabTitle: 'Groovy',
      tabInfo: upload2FilesGroovy
    }, {
      tabTitle: 'Objective-C',
      tabInfo: upload2FilesObjectiveC
    }, {
      tabTitle: 'Swift',
      tabInfo: upload2FilesSwift
    }]} mdxType="Tabs" />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <p>{`Returns a `}<a parentName="p" {...{
        "href": "#the-file-object"
      }}>{`file object`}</a>{` if the ID exist.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "createdOn": "2020-05-15T09:54:12-06:00",
        "modifiedOn": "2020-05-15T09:54:12-06:00",
        "uri": "/nodes/upload/v2fFF5ZFnESuP3usjl5yHw",
        "fileExProperties": {
            "preview_available": "False",
            "thumbnail_available": "False",
            "document_state": "DocumentPending",
            "upload_uri": "/nodes/upload/v2fFF5ZFnESuP3usjl5yHw/qOKhq_gaLE6462IOKsvQig",
            "upload_status": {
                "length": 144346,
                "original_length": 144346,
                "md5_checksum": "",
                "compressed": false,
                "write_offset": 0
            },
            "storage_location": "CLOUD",
            "account_name": "SmartVault Account"
        }
    }
}
`}</code></pre>
    </Expander>
    <br />
    <p>{`Returns an `}<a parentName="p" {...{
        "href": "/api/errors#error-object"
      }}>{`error object`}</a>{` if the file id doesn't exist.`}</p>
    <Expander title="error object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": false,
        "error_code": -520093684,
        "error_text": "Object not found.",
        "instance_guid": "9e34027c-43f5-4808-a5d8-a619507c3df9",
        "status_code": 404
    }
}
`}</code></pre>
    </Expander>
    <br />
    <h4 {...{
      "id": "the-file-document-version-id"
    }}>{`The file document version ID`}</h4>
    <hr></hr>
    <p>{`In the `}<inlineCode parentName="p">{`fileExProperties`}</inlineCode>{` object you can see the url you'd need to execute to upload the file bits to the file. In the example given:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`/nodes/adlopu / v2fFF5ZFnESuP3usjl5yHw / qOKhq_gaLE6462IOKsvQig;
`}</code></pre>
    <br />
    <List values={[{
      title: 'File ID: v2fFF5ZFnESuP3usjl5yHw.'
    }, {
      title: 'Document version ID: qOKhq_gaLE6462IOKsvQig. (We will use this in the next step)'
    }]} mdxType="List" />
    <br />
    <br />
    <br />
    <h3 {...{
      "id": "upload-the-file"
    }}>{`Upload the file`}</h3>
    <hr></hr>

    <Row mdxType="Row">
	<div>
		<p>
			Upload the file bits to the file. We need the <a href="#the-file-id">file id</a> and the{' '}
			<a href="#the-file-document-version-id">document version id</a> described before.
			<br />
			You should be able to use the "upload_uri" of the previous step return object as the endpoint url.
		</p>
	</div>
	<Table title="Upload 3rd step" rows={baseUrlUpload3} mdxType="Table" />
    </Row>
    <br />
    <p><strong parentName="p">{`Headers`}</strong></p>
    <hr></hr>
    <p>{`We need to set a specific header for this request:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`Content-Type: application/binary
`}</code></pre>
    <br />
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'file',
      type: 'binary',
      description: 'The file.'
    }]} mdxType="Attributes" />
    <br />
    <br />
    <p><strong parentName="p">{`Request`}</strong></p>
    <hr></hr>
    <Tabs items={[{
      tabTitle: 'Raw',
      tabInfo: upload3FilesRaw
    }, {
      tabTitle: 'cURL',
      tabInfo: upload3FilesCurl
    }, {
      tabTitle: 'Java',
      tabInfo: upload3FilesJava
    }, {
      tabTitle: 'Javascript',
      tabInfo: upload3FilesJavascript
    }, {
      tabTitle: 'Node',
      tabInfo: upload3FilesNode
    }, {
      tabTitle: 'Perl',
      tabInfo: upload3FilesPerl
    }, {
      tabTitle: 'Python',
      tabInfo: upload3FilesPython
    }, {
      tabTitle: 'PHP',
      tabInfo: upload3FilesPhp
    }, {
      tabTitle: 'Ruby',
      tabInfo: upload3FilesRuby
    }, {
      tabTitle: 'Go',
      tabInfo: upload3FilesGo
    }, {
      tabTitle: 'C#',
      tabInfo: upload3FilesC
    }, {
      tabTitle: 'Visual Basic',
      tabInfo: upload3FilesVB
    }, {
      tabTitle: 'Groovy',
      tabInfo: upload3FilesGroovy
    }, {
      tabTitle: 'Objective-C',
      tabInfo: upload3FilesObjectiveC
    }, {
      tabTitle: 'Swift',
      tabInfo: upload3FilesSwift
    }]} mdxType="Tabs" />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <p>{`Returns a `}<a parentName="p" {...{
        "href": "#the-file-object"
      }}>{`file object`}</a>{` if the object exist.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "name": "newUploadedFile.jpg",
        "description": "",
        "createdOn": "2020-05-15T09:54:12-06:00",
        "modifiedOn": "2020-05-15T11:10:53-06:00",
        "size": 14436,
        "uri": "/nodes/upload/v2fFF5ZFnESuP3usjl5yHw/AXzf17PTwUCSj_vVHGnZSw",
        "dav_uri": "/nodes/pth/SmartVault Account/Clients/A-D/AutoInsurance, Test for/Test/newUploadedFile.jpg",
        "download_uri": "/files/id/Document/v2fFF5ZFnESuP3usjl5yHw",
        "your_permissions": 41943153,
        "nodeType": "FileNodeType",
        "link_uri": "https://my.smartvault.com/users/secure/ElementBrowser.aspx?type=Document&id=v2fFF5ZFnESuP3usjl5yHw",
        "preview_link_uri": "https://my.smartvault.com/users/secure/ElementBrowser.aspx?type=Document&id=v2fFF5ZFnESuP3usjl5yHw",
        "download_link_uri": "https://my.smartvault.com/users/secure/DownloadFile.aspx?d=v2fFF5ZFnESuP3usjl5yHw",
        "total_children": 0,
        "fileExProperties": {
            "preview_available": "False",
            "thumbnail_available": "False",
            "document_state": "DocumentAvailable",
            "upload_status": {
                "length": 14436,
                "original_length": 14436,
                "md5_checksum": "q95yylpa9H/Ji576gU2wvA==",
                "compressed": false,
                "write_offset": 14436
            },
            "storage_location": "CLOUD",
            "account_name": "SmartVault Account"
        },
        "auto_name": false,
        "auto_description": false
    }
}
`}</code></pre>
    </Expander>
    <br />
    <p>{`Returns an `}<a parentName="p" {...{
        "href": "/api/errors#error-object"
      }}>{`error object`}</a>{` if the file size doesn't match the length that was specified in the previous request.`}</p>
    <Expander title="error object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": false,
        "error_code": -520093656,
        "error_text": "Invalid Range Error.",
        "instance_guid": "ca4514f1-8b0b-4cb2-8c33-c0b7ae25ec2c",
        "status_code": 500
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />

    <h2 {...{
      "id": "download-a-file"
    }}>{`Download a file`}</h2>
    <Row mdxType="Row">
	<div>
		<p>
			Downloading files requires the document ID.
			<br />
			You can find the ID, the endpoint <span style={{
            "color": "#3c76e9"
          }}>GET</span> request path ("download_uri") and
			the direct link to download the file ("download_link_uri") on the File object as a successful call to the{' '}
			<a href="#retrieve-file-information">getting file information request.</a>
		</p>
	</div>
	<Table title="Get file" rows={baseUrlDownload} mdxType="Table" />
    </Row>
    <br />
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'doc_id',
      type: 'number',
      description: 'The file ID of the file being retrieved'
    }]} mdxType="Attributes" />
    <br />
    <br />
    <p><strong parentName="p">{`Request`}</strong></p>
    <hr></hr>
    <Tabs items={[{
      tabTitle: 'cURL',
      tabInfo: downloadFilesCurl
    }, {
      tabTitle: 'Java',
      tabInfo: downloadFilesJava
    }, {
      tabTitle: 'Javascript',
      tabInfo: downloadFilesJavascript
    }, {
      tabTitle: 'Node',
      tabInfo: downloadFilesNode
    }, {
      tabTitle: 'Perl',
      tabInfo: downloadFilesPerl
    }, {
      tabTitle: 'Python',
      tabInfo: downloadFilesPython
    }, {
      tabTitle: 'PHP',
      tabInfo: downloadFilesPhp
    }, {
      tabTitle: 'Ruby',
      tabInfo: downloadFilesRuby
    }, {
      tabTitle: 'Go',
      tabInfo: downloadFilesGo
    }, {
      tabTitle: 'C#',
      tabInfo: downloadFilesC
    }, {
      tabTitle: 'Visual Basic',
      tabInfo: downloadFilesVB
    }, {
      tabTitle: 'Groovy',
      tabInfo: downloadFilesGroovy
    }, {
      tabTitle: 'Objective-C',
      tabInfo: downloadFilesObjectiveC
    }, {
      tabTitle: 'Swift',
      tabInfo: downloadFilesSwift
    }]} mdxType="Tabs" />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <p>{`Will return the file itself if the file ID specified is correct and / or exists.`}</p>
    <p>{`Returns an `}<a parentName="p" {...{
        "href": "/api/errors#error-object"
      }}>{`error object`}</a>{` if the file id doesn't exist.`}</p>
    <Expander title="error object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": false,
        "error_code": -520093684,
        "error_text": "Object not found.",
        "instance_guid": "9e34027c-43f5-4808-a5d8-a619507c3df9",
        "status_code": 404
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />
    <h2 {...{
      "id": "delete-a-file"
    }}>{`Delete a file`}</h2>

    <Row mdxType="Row">
	<div>
		<p>
			Delete a file at the given path. Be careful to specify the filename, otherwise you'll be deleting the whole
			folder.
		</p>
	</div>
	<Table title="Delete file" rows={baseUrlDelete} mdxType="Table" />
    </Row>
    <br />
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'path',
      type: 'string',
      description: 'The file path; including the account, vault and the full file name (including extension)'
    }]} mdxType="Attributes" />
    <br />
    <br />
    <p><strong parentName="p">{`Request`}</strong></p>
    <hr></hr>
    <Tabs items={[{
      tabTitle: 'cURL',
      tabInfo: deleteFilesCurl
    }, {
      tabTitle: 'Java',
      tabInfo: deleteFilesJava
    }, {
      tabTitle: 'Javascript',
      tabInfo: deleteFilesJavascript
    }, {
      tabTitle: 'Node',
      tabInfo: deleteFilesNode
    }, {
      tabTitle: 'Perl',
      tabInfo: deleteFilesPerl
    }, {
      tabTitle: 'Python',
      tabInfo: deleteFilesPython
    }, {
      tabTitle: 'PHP',
      tabInfo: deleteFilesPhp
    }, {
      tabTitle: 'Ruby',
      tabInfo: deleteFilesRuby
    }, {
      tabTitle: 'Go',
      tabInfo: deleteFilesGo
    }, {
      tabTitle: 'C#',
      tabInfo: deleteFilesC
    }, {
      tabTitle: 'Visual Basic',
      tabInfo: deleteFilesVB
    }, {
      tabTitle: 'Groovy',
      tabInfo: deleteFilesGroovy
    }, {
      tabTitle: 'Objective-C',
      tabInfo: deleteFilesObjectiveC
    }, {
      tabTitle: 'Swift',
      tabInfo: deleteFilesSwift
    }]} mdxType="Tabs" />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <p>{`Returns a default success object or an `}<a parentName="p" {...{
        "href": "/api/errors#error-object"
      }}>{`error object`}</a>{` if the file you are trying to delete doesn't exist.`}</p>
    <Expander title="result object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {}
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      